import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Button,
    Col,
    Container,
    Row,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
    Label,
    Card,
    CardBody,
    CardImg
} from "reactstrap";
import AWSS3Uploader from "../../components/Common/new/s3uploader";
import { addBlogBanner, getBlogBanner } from "../../requests";

const BlogBanners = () => {
    const [modal, setModal] = useState(false);
    const [bannerType, setBannerType] = useState(""); 
    const [payload, setPayload] = useState({ type: "", imageUrl: "", link: "" });
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await getBlogBanner();
            if (response?.success) {
                setBanners(response.banners || []);
            }
        } catch (error) {
            console.error("Error fetching banners:", error);
        }
    };

    const toggleModal = (type = "") => {
        setBannerType(type || ""); 
        setModal(!modal);
        setPayload({ type: type || "", imageUrl: "", link: "" });
    };

    const handleUploadComplete = (url) => {
        setPayload((prev) => ({ ...prev, imageUrl: url }));
    };

    const handleSave = async () => {
        try {
            if (!payload.imageUrl || !payload.link) {
                alert("Please provide both an image and a link.");
                return;
            }

            const response = await addBlogBanner(payload);
            if (response?.success) {
                fetchBanners();
                setModal(false);
            } else {
                console.error("Failed to save banner:", response);
            }
        } catch (error) {
            console.error("Error saving banner:", error);
        }
    };

    const handleInputChange = (e) => {
        setPayload((prev) => ({ ...prev, link: e.target.value }));
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Skrill Network" breadcrumbItem="Blog Banners" />
                <Row>
                    <Col lg={12}>
                        <Button color="primary" className="me-2" onClick={() => toggleModal("square")}>
                            Add Square Banner
                        </Button>
                        <Button color="primary" onClick={() => toggleModal("horizontal")}>
                            Add Horizontal Banner
                        </Button>
                    </Col>
                </Row>

                <Row className="mt-4">
                    {banners.map((banner) => (
                        <Col lg={6} key={banner._id}>
                            <Card>
                                <CardImg top src={banner.imageUrl} alt={`${banner.type} banner`} />
                                <CardBody>
                                    <h5>{banner.type.charAt(0).toUpperCase() + banner.type.slice(1)} Banner</h5>
                                    <p>
                                        <strong>Link:</strong>{" "}
                                        <a href={banner.link} target="_blank" rel="noopener noreferrer">
                                            {banner.link}
                                        </a>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>

                {/* Modal */}
                <Modal isOpen={modal} toggle={() => toggleModal()}>
                    <ModalHeader toggle={() => toggleModal()}>
                        Add {bannerType ? bannerType.charAt(0).toUpperCase() + bannerType.slice(1) : ""} Banner
                    </ModalHeader>
                    <ModalBody>
                        <Label for="bannerLink">Banner Link</Label>
                        <Input
                            type="text"
                            id="bannerLink"
                            placeholder="Enter link"
                            value={payload.link}
                            onChange={handleInputChange}
                        />
                        <AWSS3Uploader onUploadComplete={handleUploadComplete} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleSave}>
                            Save
                        </Button>
                        <Button color="secondary" onClick={() => toggleModal()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

export default BlogBanners;