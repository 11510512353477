import { Container, Row, Col } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getPost } from "../../requests";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PostCard from "../../components/Common/new";
import EditPostModal from "../../components/Common/new/editmodal";
import { updatePost } from "../../requests";
import { notification, Modal, Button, Spin, Pagination } from "antd";
import { deletepost, getPostPage } from "../../requests";

const AllPosts = () => {
  const PAGE_SIZE = 15;
  const [postFromResponse, setPostfromresponse] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const allPosts = async () => {
    try {
      setLoading(true);
      const resp = await getPostPage(currentPage);
      setPostfromresponse(resp);
      console.log(resp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    allPosts();
  }, [currentPage]);

  const handleEditClick = (post) => {
    setSelectedPost(post);
    toggleEditModal();
  };

  const toggleEditModal = (post) => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const openmodal = (post) => {
    setSelectedPost(post);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSaveChanges = async (updatedData) => {
    console.log(updatedData);
    try {
      const id = selectedPost._id;
      const resp = await updatePost(updatedData, id);
      console.log(resp);

      notification.success({
        message: "Post Updated",
        description: "The post has been updated successfully.",
      });
      allPosts();
    } catch (err) {
      console.log(err);

      notification.error({
        message: "Error",
        description: "An error occurred while updating the post.",
      });
    } 
    finally {
      window.location.reload();
    }
  };

  const handleDeleteClick = async () => {
    try {
      setLoading(true);
      // const token =localStorage.getItem('token')
      // console.log(selectedPost)
      const resp = await deletepost(selectedPost);
      console.log(resp);

      notification.success({
        message: "Post Deleted",
        description: "The post has been deleted successfully.",
      });
    } catch (err) {
      notification.error({
        message: "Failed",
        // description: "The post has been deleted successfull",
      });
      console.log(err);
    } finally {
      setLoading(false);
      allPosts();
    }

    console.log(selectedPost);

    setIsModalVisible(false);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPosts = postFromResponse.length;
  const totalPages = Math.ceil(totalPosts / PAGE_SIZE);

  const startIdx = (currentPage - 1) * PAGE_SIZE;
  const endIdx = startIdx + PAGE_SIZE;

 

  return (
    <React.Fragment>
      <Spin spinning={loading} fullscreen />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Skrill Network" breadcrumbItem="All Posts" />
          <div >
            <Row>
              {postFromResponse?.map((post) => (
                <Col sm={6} md={4} lg={3} key={post._id}>
                  <PostCard
                    post={post}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={openmodal}
                  />
                </Col>
              ))}
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Pagination

                current={currentPage}
                total={200}
                pageSize={totalPages}
                onChange={onPageChange}
              />
            </div>
          </div>
        </Container>
      </div>
      <EditPostModal
        post={selectedPost}
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModal}
        handleSaveChanges={handleSaveChanges}
      />

      <Modal
        title="Delete Post"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            danger
            onClick={handleDeleteClick}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete the post?</p>
      </Modal>
    </React.Fragment>
  );
};

export default AllPosts;
