import React from "react";
import { useRef } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import {
  getcategory,
  gettags,
  addPost,
  getcorporate,
  getcountry,
} from "../../requests";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
// import { Editor } from '@ckeditor/ckeditor5-core';
import dayjs from "dayjs";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import { useState, useEffect } from "react";
import FormUpload from "../../Pages/Forms/FormUpload";
import {
  Form as Antform,
  Input,
  Select,
  Space,
  DatePicker,
  notification,
  Spin
} from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FormEditors from "../../Pages/Forms/FormEditors";
import { Link ,useNavigate} from "react-router-dom";
import { S3 } from "aws-sdk";


const AddPost = () => {
  const navigate=useNavigate()
  const { Option } = Select;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const[imageSource,setImageSource]=useState("")
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCorporate, setselectedCorporate] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [corporates, setcorporates] = useState([]);
  const [editorContent, setEditorContent] = useState(EditorState.createEmpty());
  const [postTitle, setPostTitle] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [Categories, setCategories] = useState([]);
const [loading,setLoading]  = useState(false)
  const[metaTitle,setmetaTitle]=useState("")
  const[metaDescription,setmetaDescription]=useState("")

  const [showContent, setShowContent] = useState("");

  const AWS_ACCESS_KEY_ID = "AKIA2SG3VJ3SF2O5AVLZ";
  const AWS_SECRET_ACCESS_KEY = "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";
  const AWS_DEFAULT_REGION = "ap-south-1";

  // Set AWS credentials for the AWS SDK
  const awsCredentials = {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: AWS_DEFAULT_REGION,
  };

  // S3.config.update(awsCredentials);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // const onSaveContent = (content) => {
  //   setEditorContent(content);
  // };

  const onSaveContent = (content) => {
    handleSavePost(content);
  };

  const getCategory = async () => {
    try {
      const resp = await getcategory();
      console.log(resp);
      setCategories(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const getCountries = async () => {
    try {
      const resp = await getcountry();
      console.log(resp);
      setCountries(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const getCorporate = async () => {
    try {
      const resp = await getcorporate();
      console.log(resp);
      setcorporates(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const [tags, settags] = useState([]);
  const getTags = async () => {
    try {
      const resp = await gettags();
      console.log(resp);
      settags(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
    getTags();
    getCorporate();
    getCountries();
  }, []);
  const formattedDate = selectedDate
    ? dayjs(selectedDate).format("MMM D YYYY")
    : "";

  const handleSavePost = async (content) => {
    try {
      setLoading(true)
      const uploadedFiles = await uploadToS3(selectedFiles);
      const thumbnail = uploadedFiles.length > 0 ? uploadedFiles[0] : "";

      const payload = {
        thumbnail: thumbnail,
        selectedTags: selectedTags,
        selectedCorporate: selectedCorporate,
        selectedCategories: selectedCategories,
        editorContent: editorContent,
        postTitle: postTitle,
        selectedCountries: selectedCountries,
        selectedDate: formattedDate,
        metatitle:metaTitle,
        metadescription:metaDescription,
        imageSource:imageSource
      };

      try {
       
        const resp = await addPost(payload);
        // console.log(resp);
        notification.success({
          message: "Post Saved",
          description: "Your post has been successfully saved.",
        });
       navigate('/allposts')
        // console.log(payload)
      } catch (err) {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
        console.log(err);
      }finally{
        setLoading(false)
      }
      // setShowContent(JSON.stringify(editorContent, null, 2));
    } catch (error) {
      {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      }
      console.error("Error uploading files to S3:", error);
    }
  };

  const uploadToS3 = async (files) => {
    const s3 = new S3(awsCredentials);

    const uploadPromises = files.map(async (file) => {
      const params = {
        Bucket: "skrillnetworkimages",
        Key: `${file.name}`,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
        // ACL: "public-read",
      };

      try {
        // Upload the file to S3
        const result = await s3.upload(params).promise();

        // Generate the object URL with the correct region
        const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;

        return objectUrl;
      } catch (error) {
        console.error(`Error uploading file ${file.name} to S3:`, error);
        return null; // Or handle the error as needed
      }
    });
    // Wait for all uploads to complete
    const objectUrls = await Promise.all(uploadPromises);
    return objectUrls.filter((url) => url !== null);
  };

  function uploadAdapter(loader) {
    const awsCredentials = {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
      region: AWS_DEFAULT_REGION,
    };

    const s3 = new S3(awsCredentials);

    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const params = {
              Bucket: "skrillnetworkimages",
              Key: file.name,
              Body: file,
              ContentType: file.type,
              ContentDisposition: "inline",
            };

            s3.upload(params)
              .promise()
              .then((result) => {
                const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;

                // Resolve with the S3 URL for any additional processing
                resolve({ default: objectUrl });
              })
              .catch((err) => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader, editor);
    };
  }

 
  return (
    <React.Fragment>
    <Spin spinning={loading} fullscreen/>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Skrill Network" breadcrumbItem="Add Post" />
          <div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div style={{ textAlign: "center" }}>
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                            </div>
                            <h4>Drop files here to upload</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card>
              {/* <CardHeader>
                <h4 className="card-title mb-0">Fuzzy Search</h4>
              </CardHeader> */}
              <CardBody>
                <p className="text-muted">
                  Choose Category,Tags,Corporate(if applicable)
                </p>
                <div id="fuzzysearch-list">
                  <Antform>
                    <Row gutter={15}>
                      <Col md={4}>
                        <Select
                          mode="multiple"
                          placeholder="Select Categories"
                          value={selectedCategories}
                          onChange={(values) => setSelectedCategories(values)}
                          style={{ width: "100%" }}
                        >
                          {Categories.map((category) => (
                            <Option key={category._id} value={category.name}>
                              {category.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      {/* Tags Select */}
                      <Col md={4}>
                        <Select
                          mode="multiple"
                          placeholder="Select Tags"
                          value={selectedTags}
                          onChange={(values) => setSelectedTags(values)}
                          style={{ width: "100%" }}
                        >
                          {tags.map((tag) => (
                            <Option key={tag._id} value={tag.name}>
                              {tag.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col md={4}>
                        <Select
                          mode="multiple"
                          placeholder="Select Corporate"
                          // value={selectedTags}
                          onChange={(values) => setselectedCorporate(values)}
                          style={{ width: "100%" }}
                        >
                          {corporates.map((category) => (
                            <Option key={category._id} value={category.name}>
                              {category.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col md={4} style={{marginTop:'10px'}}>
                        <Select
                          mode="multiple"
                          placeholder="Select Country"
                          // value={selectedTags}
                          onChange={(values) => setSelectedCountries(values)}
                          style={{ width: "100%" }}
                        >
                          {countries.map((category) => (
                            <Option key={category._id} value={category.name}>
                              {category.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Antform>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                {/* ... (previous code) */}
                <Row>
                  <Col>
                    <Antform.Item label="Post Title">
                      <Input
                        value={postTitle}
                        onChange={(e) => setPostTitle(e.target.value)}
                      />
                    </Antform.Item>

                    <Antform.Item label="Image Source">
                      <Input
                        value={imageSource}
                        onChange={(e) => setImageSource(e.target.value)}
                      />
                    </Antform.Item>

                    <Antform.Item label="Meta Title">
                      <Input placeholder="Enter Meta Title" 
                      onChange={(e)=>setmetaTitle(e.target.value)}
                      />
                      
                    </Antform.Item>
                  </Col>
                  <Col>
                    <Antform.Item label="Select Date">
                      <DatePicker
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                      />
                    </Antform.Item>
                    <Antform.Item label="Meta Description">
                      <Input placeholder="Enter Meta Title" 
                      onChange={(e)=>setmetaDescription(e.target.value)}
                      />
                    </Antform.Item>
                  </Col>
                </Row>
                {/* ... (previous code) */}
              </CardBody>
            </Card>

            {/* <FormEditors onSaveContent={onSaveContent} /> */}

            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
              }}
              // onReady={(editor) => (editor.ref = editor)}
              editor={ClassicEditor}
              data="<p>Hello from CKEditor&nbsp;5!</p>"
              onReady={(editor) => {
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditorContent(data);
                console.log("Content change:", data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />

            <button
              type="button"
              className="btn btn-primary "
              onClick={handleSavePost}
              style={{ marginBottom: "10px" }}
            >
              Save Content
            </button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddPost;
