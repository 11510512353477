// PostCard.jsx
import React from 'react';
import { Button } from "reactstrap";
import styles from './styles.module.css';

const PostCard = ({ post, handleEditClick, handleDeleteClick }) => {
// console.log(post);
  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + '...';
    }
    return title;
  };

  return (
    <div key={post._id} className={styles.postCard}>
      <img src={post.thumbnail} alt="Thumbnail" className={styles.thumbnail} />
      <div className={styles.postContent}>
        <p className={styles.postTitle}>{truncateTitle(post.postTitle, 50)}</p>
        <p > {new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, 
  }).format(new Date(post.createdAt))}</p>
        {/* <div dangerouslySetInnerHTML={{ __html: post.editorContent }} /> */}
        <div className={styles.postActions}>
        <Button color="info" onClick={() => handleEditClick(post)}>Edit</Button>
          <Button color="danger" onClick={() => handleDeleteClick(post._id)}>Delete</Button>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
