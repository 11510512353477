import axios from "axios";

const BASE_URL="https://api.skrillnetwork.com"
// const BASE_URL = "http://localhost:4000";



// const apiKey = process.env.REACT_APP_API_KEY;

const adminlogin=async(value)=>{
  const response = await axios.post(`${BASE_URL}/auth/login`, value);
  return response;
}

const addcategory = async (value,token) => {
  
  const response = await axios.post(`${BASE_URL}/categories/add`, value,{headers:{Authorization:token}});
  return response;
};

const getcategory = async (value) => {
  const response = await axios.get(`${BASE_URL}/categories/get`);
  return response;
};

const deleteCategory = async (categoryId,token) => {

  const response = await axios.delete(
    `${BASE_URL}/categories/delete/${categoryId}`,{headers:{Authorization:token}}
  );
  return response;
};

const addtags = async (value) => {
  const token =localStorage.getItem('token')
  const response = await axios.post(`${BASE_URL}/tags/add`, value,{headers:{Authorization:token}});
  return response;
};

const gettags = async () => {
  const response = await axios.get(`${BASE_URL}/tags/get`);
  return response;
};


const addcorporate = async (value,token) => {
  
    const response = await axios.post(`${BASE_URL}/corporate/add`, value,{headers:{Authorization:token}});
    return response;
  };
  
  const getcorporate = async () => {
    const response = await axios.get(`${BASE_URL}/corporate/get`);
    return response;
  };

  const deleteCorporate = async (Id) => {
    const token =localStorage.getItem('token')
    const response = await axios.delete(
      `${BASE_URL}/corporate/delete/${Id}`,{headers:{Authorization:token}}
    );
    return response;
  };

  const addcountry = async (value) => {
    const token =localStorage.getItem('token')
    const response = await axios.post(`${BASE_URL}/country/add`, value,{headers:{Authorization:token}});
    return response;
  };
  
  const getcountry = async () => {
    const response = await axios.get(`${BASE_URL}/country/get`);
    return response;
  };

  const deleteCountry = async (Id) => {
    const token =localStorage.getItem('token')
    const response = await axios.delete(
      `${BASE_URL}/country/delete/${Id}`,{headers:{Authorization:token}}
    );
    return response;
  };

  const addPost=async(values)=>{
    const token =localStorage.getItem('token')
    const response = await axios.post(
        `${BASE_URL}/post/add`,values,{headers:{Authorization:token}}
      );
      return response
  }

  const getPost=async()=>{
    const response = await axios.get(
        `${BASE_URL}/post/get`
      );
      return response
  }

  const getPostPage=async(number)=>{
    const response = await axios.get(
        `${BASE_URL}/post/getpage?page=${number}`
      );
      return response
  }

  const deletepost=async(values)=>{
    
    const response = await axios.delete(
        `${BASE_URL}/post/delete/${values}`
      );
      return response
  }
  

  const deletetag=async(values)=>{
    const token =localStorage.getItem('token')
    const response = await axios.delete(
        `${BASE_URL}/tags/delete/${values}`,{headers:{Authorization:token}}
      );
      return response
  }

  const updatePost=async(values,id)=>{
    const response = await axios.put(
      `${BASE_URL}/post/update/${id}`,values
    );
    return response
  }

  const deepDive=async(values,token)=>{
    const response = await axios.post(
      `${BASE_URL}/deepdive/add`,values,{headers:{Authorization:token}})
      return response
  }

  const updatedeepDive=async(values,token,id)=>{
    const response = await axios.put(
      `${BASE_URL}/deepdive/update/${id}`,values,{headers:{Authorization:token}})
      return response
  }

  const companies=async(values)=>{
    const response = await axios.get(
      `${BASE_URL}/deepdive/companies`,values)
      return response
  }

  const deepdivebyid=async(id)=>{
    const response = await axios.get(
      `${BASE_URL}/deepdive/detail/${id}`)
      return response
  }

  const deletedeepDive=async(token,id)=>{
    const response = await axios.delete(
      `${BASE_URL}/deepdive/delete/${id}`,{headers:{Authorization:token}})
      return response
  }

  const addBanner=async(values)=>{
    const token =localStorage.getItem('token')
    const response = await axios.post(
      `${BASE_URL}/banners/add`,values,{headers:{Authorization:token}})
      return response
  }

  const getBanner=async(values)=>{
    // const token =localStorage.getItem('token')
    const response = await axios.get(
      `${BASE_URL}/banners`,values)
      return response
  }

  const deleteBanner=async(id)=>{
    const token =localStorage.getItem('token')
    const response = await axios.delete(
      `${BASE_URL}/banners/${id}`,{headers:{Authorization:token}})
      return response
  }

  export const updateBanner=async(id,values)=>{
    const token =localStorage.getItem('token')
    const response = await axios.put(
      `${BASE_URL}/banners/${id}`,values,{headers:{Authorization:token}})
      return response
  }

  const addClient=async(values)=>{
    
    const response = await axios.post(
      `${BASE_URL}/clients/add`,values)
      return response
  }

  const getClient=async()=>{
    
    const response = await axios.get(
      `${BASE_URL}/clients/get`)
      return response
  }

  const deleteClient=async(id)=>{
  
    const response = await axios.delete(
      `${BASE_URL}/clients/delete/${id}`)
      return response
  }

  const videos=async()=>{
    const response = await axios.get(
      `${BASE_URL}/videos/get`)
      return response
  }

  const addVideo=async(values)=>{
    
    const response = await axios.post(
      `${BASE_URL}/videos/add`,values)
      return response
  }

  const deleteVideo=async(id)=>{
  
    const response = await axios.delete(
      `${BASE_URL}/videos/${id}`)
      return response
  }

  const podcasts=async()=>{
    const response = await axios.get(
      `${BASE_URL}/podcast/get`)
      return response
  }

  const addPodcast=async(values)=>{
    
    const response = await axios.post(
      `${BASE_URL}/podcast/add`,values)
      return response
  }

  const deletePodcast=async(id)=>{
  
    const response = await axios.delete(
      `${BASE_URL}/podcast/delete/${id}`)
      return response
  }

  const addads=async(values)=>{
   
    const response = await axios.post(
      `${BASE_URL}/ads/add`,values)
      return response
  }

  const getADS=async()=>{
    const response = await axios.get(
      `${BASE_URL}/ads/get`)
      return response
  }

  const deleteADS=async(id)=>{
  
    const response = await axios.delete(
      `${BASE_URL}/ads?page=${id}`)
      return response
  }

  const dashboarddata=async()=>{
    const response = await axios.get(
      `${BASE_URL}/dashboard`)
      return response
  }

  const addstockexchange = async (value) => {
    const token =localStorage.getItem('token')
    const response = await axios.post(`${BASE_URL}/stockexchange/add`, value,{headers:{Authorization:token}});
    return response;
  };
  
  const getstockexchange = async () => {
    const response = await axios.get(`${BASE_URL}/stockexchange/get`);
    return response;
  };

  const deletestockexchange = async (Id) => {
    const token =localStorage.getItem('token')
    const response = await axios.delete(
      `${BASE_URL}/stockexchange/delete/${Id}`,{headers:{Authorization:token}}
    );
    return response;
  };


  export const addBlogBanner=async(values)=>{
    const token =localStorage.getItem('token')
    const response = await axios.post(
      `${BASE_URL}/blog-banners/banners`,values,{headers:{Authorization:token}})
      return response
  }

  export const getBlogBanner=async()=>{
    const response = await axios.get(
      `${BASE_URL}/blog-banners/banners`)
      return response
  }


export {dashboarddata,deleteADS,getADS,addads,videos,addVideo,deleteVideo,podcasts,addPodcast,deletePodcast,deleteClient,getClient,addClient,addBanner,getPostPage,deletepost,deletedeepDive,updatedeepDive,deepdivebyid,companies,deepDive,updatePost, addcountry,getcountry,deleteCountry,getPost,deletetag,addcategory, getcategory, deleteCategory,addtags,gettags ,addcorporate,getcorporate,deleteCorporate,addPost,adminlogin,addstockexchange,getstockexchange,deletestockexchange,getBanner,deleteBanner};
